html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

body {
    font-family: 'Poppins', sans-serif;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    padding: 0 20px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 61px;
    margin-bottom: 65px;
}

h1 .thin {
    font-weight: 300;
}

h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 33px;
    line-height: 42px;
    margin-bottom: 32px;
    margin-top: 70px;
    color: #1C7389;
    text-align: center;
}
h3{
    font-size: normal;
    font-weight: bold;
    font-size: 22px;
    color: #595959;
    text-transform: uppercase;
    line-height: 28px;
    margin-bottom: 25px;
}

p {
    font-size: 15px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 25px;
}
p:last-child{
    margin-bottom: 0;
}
section p {
    color: #6F6C70;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}
.scroll-to-link *{
    pointer-events: none;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    position: relative;
    z-index: 10;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    text-align: left;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }



.site-logo {
    font-size: 18px;
    color: transparent;
    background: url(../image/logo.png);
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
}

.logo-wrapper {
    display: flex;
    height: 161px;
    justify-content: center;
    padding-bottom: 22px;
    padding-top: 100px;
    position: relative;
    z-index: 1;
    width: 220px;
    margin: 0 auto 50px;
}
.site-header {
    background-color: #0A3F4D;
    color: #fff;
    overflow: hidden;
    position: relative;
}
.site-header-inner {
    position: relative;
    padding: 0 60px 105px;
    text-align: center;
}

.header-image {
    position: absolute;
    right: 0;
    bottom: 0;

    /* TODO */
}



.header-description {
    margin-bottom: 60px;
    width: 487px;
    margin: 0 auto;
}
.nav-wrapper{
    font-size: 17px;
    font-weight: 700;
    margin: 0 auto;
    position: relative;
    width: 915px;
}
.nav-wrapper-bg{
    background-color: #fff;
    border-radius: 69px;
    bottom: 0;
    left: 0;
    opacity: .15;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.nav-wrapper ul {
    display: flex;
    justify-content: space-around;
}


.nav-wrapper li {
    margin-right: 31px;
}

.nav-wrapper a {
    align-items: center;
    background-position: 0;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    position: relative;
}

.nav-wrapper a:hover {
    color: #66E0FF;
    transition: color 0.2s;
}


.header-description {
    margin-bottom: 60px;
    width: 487px;
    margin: 0 auto;
}
.more{
    display: block;
    transform: scaleY(0);
    height: 0;
    transition: transform 0.12s ease;
}
.site-header-inner.show-more .more{
    display: block;
    transform: scaleY(1);
    height: 170px;
    transition: transform 0.12s ease;
}
.site-header-inner.show-more .header-description {
    width: 80%;
}
.site-header-inner.show-more .dots{
    display: none;
}
.site-header-inner.show-more .cta-btns{
    margin-top: 40px;
}
.click-for-more{
    height: 16px;
    width: 16px;
    display: inline-block;
    background-repeat: no-repeat;
    display: inline-block;
    background-size: contain;
    background-image: url("../image/more-text.png");
    cursor: pointer;
}

.nav-wrapper p {
    margin-bottom: 54px;
    letter-spacing: 0.345em;
}

.nav-wrapper ul {
    display: flex;
    justify-content: space-around;
}

.nav-wrapper li {
    margin-right: 31px;
}

.nav-wrapper a {
    align-items: center;
    background-position: 0;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    position: relative;
}



.cta-btns{
    padding: 15px 50px;
    position: relative;
    z-index: 2;
    margin-top: 90px;
}
.cta-btns span{
    position: relative
}
.cta-btns li:first-of-type span:before{
    background-image: url("../image/award-icon.png");
    left: -25px;
    top: 2px; 
}
.cta-btns li:nth-of-type(2) span:before{
    background-image: url("../image/key-icon.png");
    left: -25px;
    top: 2px; 
}
.cta-btns li:nth-of-type(3) span:before{
    background-image: url("../image/invest-icon.png");
    left: -25px;
    top: 2px; 
}
.cta-btns li:nth-of-type(4) span:before{
    background-image: url("../image/faqs.png");
    left: -25px;
    top: 2px; 
}
.cta-btns li a:hover span:before{
    filter: invert(21%) sepia(31%) saturate(836%) hue-rotate(164deg) brightness(105%) contrast(102%);
}

.cta-btns span:before{
    background-repeat: no-repeat;
    content: "";
    height: 25px;
    left: -25px;
    position: absolute;
    top: 2px;
    transition: filter .2s ease-in-out;
    width: 25px;
}
.hero-image{
    position: relative;
}
.image-wrapper{
    background-image: url("../image/bg-gradient.webp");
    height: 1800px;
    left: 193px;
    position: absolute;
    width: 50%;
    width: 2082px;
    top: -750px;
}
.bg-image{
    background-image: url("../image/header-bg.png");
    height: 1800px;
    left: -300px;
    position: absolute;
    width: 50%;
    width: 2082px;
    top: 80px;
    background-repeat: no-repeat;
}

/* TABLE  */

.home-table{
    background-color: #1C7389;
    border: 0;
    border-collapse: separate;
    border-radius: 26px;
    border-spacing: 0 10px;
    color: #2e2056;
    font-size: 14px;
    padding: 17px;
    width: 100%;
}
.home-table thead{
    color: #fff;
    font-style: normal;
    text-transform: uppercase;
}
.home-table th{
    font-weight: 400!important;
}
.home-table td:first-child {
    border-bottom-left-radius: 28px;
    border-left-style: solid;
    border-top-left-radius: 28px;
}
.home-table .t-logo{
    background-position: 50%;
    background-repeat: no-repeat;
    height: 91px;
    line-height: 91px;
    text-indent: -5000px;
    width: 210px;
}
.home-table .t-logo{
    border-radius: 25px;
    color: #fff;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    background-color: #F1F4F9;
}
.home-table td {
    background-color: #f9fbff;
    border-color: #000;
    border-style: solid none;
    border-width: 0;
    padding: 6px;
    position: relative;
}
.home-table td:last-child {
    border-bottom-right-radius: 28px;
    border-right-style: solid;
    border-top-right-radius: 28px;
}
.home-table td:nth-of-type(2) {
    font-weight: 700;
}

.home-table td:nth-of-type(3) ul li:not(:last-child) {
    margin-bottom: 15px;
}
.home-table li {
    margin-left: 20px;
}

.home-table td:nth-of-type(3) ul li:before {
    color: #72dbf6;
    content: "\2022";
    display: inline-block;
    font-weight: 700;
    margin-left: -1em;
    width: 1em;
}
.home-table td:nth-of-type(2):after, .home-table td:nth-of-type(3):after {
    background-color: #f1f4f9;
    content: "";
    height: 80%;
    position: absolute;
    right: 0;
    top: 10px;
    width: 1px;
}
 .rating-img {
    background-image: url(../image/star.png);
    float: left;
    height: 23px;
    margin-right: 14px;
    width: 23px;
}
.home-table td:nth-of-type(4) {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
}
.home-table .t-site-link {
    background: #F6A62E;
    border-radius: 69px!important;
    box-shadow: 0 -28px 73px -11px hsl(261deg 8% 53% / 50%);
    height: 56px;
    line-height: 56px;
    margin: 0 auto;
    text-decoration: none;
    width: 181px;
}
.home-table .t-logo, .home-table .t-site-link {
    border-radius: 25px;
    color: #fff;
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}

.home-table tr td:first-of-type {
    border-left: 2px solid transparent;
}
.home-table tr td:last-of-type {
    border-right: 2px solid transparent;
}
.home-table tr td {
    border-bottom: 2px solid transparent;
    border-top: 2px solid transparent;
    /* border-right: 2px solid #ffc737; */
}

tr:first-of-type .t-logo {
    background-image: url(../image/decentraland-logo.png);
}
tr:nth-of-type(2) .t-logo {
    background-image: url(../image/axie-logo.png);
}
tr:nth-of-type(3) .t-logo {
    background-image: url(../image/sandbox-logo.png);
}
tr:nth-of-type(4) .t-logo {
    background-image: url(../image/embersword-logo.png);
}
tr:nth-of-type(5) .t-logo {
    background-image: url(../image/mirandus-logo.png);
}
.tabs-wrapper{
    margin-top: 50px;
}




/* TABLE HOVER EFFECTES */
.home-table tr:hover td:first-of-type {
    border-left: 2px solid #ffc737;
}
.home-table tr:hover td:last-of-type {
    border-right: 2px solid #ffc737;
}
.home-table tr:hover td {
    border-bottom: 2px solid #ffc737;
    border-top: 2px solid #ffc737;
}
.home-table tr:hover .t-site-link {
    background-color: #F6822E;
}


/* ENDS TABLE */

.what-is-nft-image{
    background-image: url("../image/nft-property.webp");
    background-size: contain;
    width: 100%;
    height: 274px;
    background-repeat: no-repeat;
    margin-top: -25px;
    background-position: center center;
}
.claim a,
.invest a{
    color: #2679DB;
}
.invest{
    margin-bottom: 70px;
}


/* FAQ SECTION */
.text-center h2{
    color: white;
}

.faq-wrapper-outer{
    background-color: #0A3F4D;
    overflow: hidden;
}
.bg-gradient{
    position: relative;
}
.bg-gradient-inner{
    background-image:url("../image/faq-image.webp");
    position: absolute;
    width: 2000px;
    height: 2000px;
    top: -565px;
    left: 372px;
    position: absolute;
    z-index: -1;
}
.faq-wrapper{
    position: relative;
    z-index: 2;
}
.faq-section-wrapper{
    padding-bottom: 90px;
}
.faq-heading {
    margin-bottom: 38px!important;
    padding-top: 68px!important
}


.faq.expanded+.faq {
    border-top: 0
}

.faq-title {
    align-items: center;
    display: flex;
    justify-content: space-between
}

.faq-title h3 {
    color: white;
    font-size: 28px;
    font-weight: 400;
    height: 98px;
    line-height: 98px;
    pointer-events: none
}
.faq-btn {
    align-items: center;
    border: 0;
    border-radius: 25px;
    display: flex;
    font-size: 42px;
    height: 49px;
    justify-content: center;
    margin-right: 42px;
    width: 49px
}



.faq-btn:hover,.faq-title:hover {
    cursor: pointer
}

.faq {
    padding-left: 40px;
    transition: background-color .2s linear,font-weight .2s linear,border-radius .2s linear;
    position: relative;
    margin-bottom: 25px;
}
.faq-background-outer{
    background-color: white;
    opacity: 0.3;
    position: absolute;
    border-radius: 40px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}
.faq-content{
    position: relative;
    z-index: 2;
}

.faq .faq-body {
    color: white;
    font-size: 17px;
    line-height: 24px;
    padding-bottom: 44px;
    border-top: 1px solid white;
    padding-top: 25px;
    width: 95%;
}
.faq.collapsed .faq-body {
    display: none
}
.faq-body a{
    font-weight: bold;
    color: white;
    text-decoration: underline;
}



.faq-btn span {
    pointer-events: none
}

.faq.collapsed .faq-btn span {
    color: white
}

.faq.expanded .faq-btn span {
    color: white
}

.faq.collapsed .faq-btn span.plus,.faq.expanded .faq-btn span.minus {
    display: block
}

.faq.collapsed .faq-btn span.minus,.faq.expanded .faq-btn span.plus {
    display: none
}
.faq.expanded .faq-title h3 {
    font-weight: 700
}
.text-center {
    text-align: center;
    color: white;
}
.text-center p{
    padding-bottom: 50px;
    color: #fff;
}
.faq-title h3{
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    display: flex;
    align-items: center;
}
.faq-body p{
    color: white;
    text-align: left;
}           /* ENDS FAQ SECTION */

/* CONTACT US SECTION  */

.contact-us-outer{
    position: relative;
}
.contact-us-outer-opacity{
    position: absolute;
    background: white;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.15;
}
.contact-us-inner{
    position: relative;
    z-index: 2;
    text-align: center;
    padding: 1px 20px 70px;
}
.contact-us-inner h2{
    
    color: white;
    font-weight: 700;  
}
.contact-us-inner p{
    color: white;
    font-size: 15px;
    margin-bottom: 50px;
}
.contact-link{
    text-decoration: underline;
    color: white;
    font-weight: bold;
}
.contact-btn{
    background-color: #F6A62E;
    font-size: 22px;
    padding: 17px 53px;
    border-radius: 69px;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: white;
    cursor: not-allowed;
    position: relative;
    font-weight: bold;
}
.contact-btn:hover:before{
    background: #fff;
    border-radius: 69px;
    bottom: -5px;
    box-shadow: 0 4px 15px rgb(26 70 79 / 30%);
    color: #1a464f;
    content: "Services under development";
    height: inherit;
    justify-content: center;
    left: 0;
    opacity: .95;
    position: absolute;
    text-align: center;
    transform: translateY(-80px);
    width: inherit;
    line-height: 20px;
    padding: 15px;
}
.contact-btn:hover:after{
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-top: 8px solid #fff;
    clear: both;
    content: "";
    height: 0;
    left: 46%;
    opacity: .95;
    position: absolute;
    top: 6px;
    transform: translateY(-16px);
    width: 0;
}



/* FOOTER SECTION */
footer{
    background-color: #355C67;
}

footer .container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #458292;
}

footer .copyright {
    color: #fff;
    padding: 12px 0 12px;
    font-size: 14px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 768px) {


    .container{
        padding: 0px 22px;
    }
    h1 {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
    }

    h2 {
        font-size: 28px;
        line-height: 36px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }
    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }
    .image-wrapper{
        left: -150px;
    }

    .header-image {
        display: none;
    }
    .bg-image{
        top: 192px;
    }
    .site-header-inner {
        padding: 26px 20px 70px;
    }

    .header-description {
        margin-bottom: 26px;
        width: 100%;
    }
    .site-header-inner.show-more .header-description{
        width: 100%;
    }
    .cta-btns{
        margin-top: 0px;
    }

    .nav-wrapper p {
        margin-bottom: 20px;
    }

    .nav-wrapper ul {
        display: block;
    }

    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }

    .nav-wrapper li {
        width: 100%;
    }
    .nav-wrapper{
        width: 100%;
    }


    .nav-wrapper-bg{
        display: none;
    }
    .transparent-bg{
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.15;
        border-radius: 69px;
    }
    .draw-software-li a{
        z-index: 10;
        background-position: 33px
    }
    .feature-li a{
        z-index: 10;
        background-position: 80px;
    }

    .cta-btns li:first-of-type a:before{
        left: 143px;
        top: 23px; 
    }
    .cta-btns li:nth-of-type(2) a:before{
      
        left: -25px;
        top: 2px; 
    }
    .cta-btns li:nth-of-type(3) a:before{
        left: -25px;
        top: 6px; 
    }
    .cta-btns li:nth-of-type(4) a:before{
        left: -25px;
        top: 2px; 
    }

    .btn-lis a{
        justify-content: center;
        padding: 20px;
        position: relative;
    }
    .cta-btns{
        padding:0;
    }



    .bg-gradient-inner{
        left: -275px;
    }
    .articles{
        padding: 0 20px;
    }
    
    .m-readmore-btn{
        background: transparent;
        border: none;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        color: white;
        width: 100%;
        margin: 15px auto 50px;
    }
    .m-readmore-btn * {
        pointer-events: none;
    }
    .m-visible {
        display: initial;
    }
    .faq-wrapper-outer{
        padding-top: 50px;
    }
    .wrapper-inner{
        padding-bottom: 50px;
        padding-top: 40px;
    }
    .wrapper-inner h2{
        font-size: var(--h2-size-mobile);
    }



    .home-table tbody tr {
        background: #f9fbff;
        border-radius: 20px;
        display: table;
        max-width: -webkit-fill-available;
        padding: 10px;
        width: 100%;
        margin-bottom: 15px;
    }
    
    .home-table td:first-of-type:before {
        content: "";
    }
    .home-table td:before {
        color: #96a0ae;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        left: 4px;
        padding-right: 10px;
        position: absolute;
        text-align: left;
        text-transform: uppercase;
        top: -6px;
        white-space: nowrap;
        width: 45%;
    }
    .t-logo {
        background: #f1f4f9;
        border-radius: 25px;
        height: auto;
        padding: 20px 10px;
        width: 100%!important;
        background-size: contain;
    }
    .home-table td:first-of-type, .home-table td:nth-of-type(2) {
        line-height: 20px;
        width: 48%;
    }
    .home-table td:nth-of-type(2):before {
        content: "Type";
    }
    .home-table td:before {
        color: #96a0ae;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        left: 4px;
        padding-right: 10px;
        position: absolute;
        text-align: left;
        text-transform: uppercase;
        top: -6px;
        white-space: nowrap;
        width: 45%;
    }
    .home-table td:nth-of-type(3) {
        float: right;
        width: 62%;
    }
    .home-table td {
        border: none!important;
        display: inline-table;
        max-width: 350px!important;
        padding: 10px 5px!important;
        position: relative;
        text-align: left!important;
    }
    .home-table td:nth-of-type(3):before {
        content: "Highlights";
    }
    .t-rating {
        color: #2e2056;
        font-size: 14px;
        justify-content: left;
        line-height: 21px;
    }
    .home-table td:nth-of-type(5) {
        width: 100%;
    }
    a.button {
        box-shadow: none;
        display: block;
        min-width: auto;
        padding: 20px;
        text-align: center;
    }
    ul.list-highlights {
        padding-left: 0;
    }
    ul.list-highlights li {
        margin-left: 10px;
    }
    .home-table td:nth-of-type(4):before {
        content: "Rating";
    }

    .home-table td:nth-of-type(4) {
        width: 28%;
    }
    .home-table thead {
    display: none;
}

    .types{
        margin-top: 0;
    }

    footer .container {
        flex-direction: column;
    }

    footer .logo-wrapper {
        padding-bottom: 8px;
    }



    .show-more-btn{
        margin-top: 170px;
    }
}


/* TABLET VERSION */
@media only screen and (max-width: 1100px){

    .show-more-btn{
        margin-top: 90px;
    }
    .cta-btns{
        margin-top: 30px;
    }
    .nav-wrapper ul {
        display: block;
    }
    .btn-lis a{
        justify-content: center;
    padding: 20px;
    }
    .nav-wrapper{
        width: 100%;
    }
    .nav-wrapper-bg{
        display: none;
    }
    .transparent-bg{
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.15;
        border-radius: 69px;
    }
    .nav-wrapper ul li:not(:last-child) {
        margin-bottom: 16px;
    }
    .best-marketplace-wrapper{
        flex-direction: column;
    }
    .best-marketplace-wrapper img{
        margin-bottom: 30px;
    }
    .image-wrapper{
        left: 65px;
    }
    .bg-gradient-inner{
        left: -170px;
    }
    .faq-section-wrapper{
        padding-bottom: 0;
    }
    
}

@media only screen and (max-width: 390px){
    .show-more-btn{
        margin-top: 222px;
    }
    .logo-wrapper{
        padding-top: 40px;
        height: 100px;
        margin-bottom: 0;
    }
    h2{
        margin-top: 50px;
    }
    .types{
        margin-top: -10px!important;
    }
    .cta-btns li:first-of-type span:before{
        left: 0px;
        top: 5px;
    }
    .cta-btns span{
        margin-left: 15px;
    }
    .site-header-inner{
        padding-bottom: 50px;
    }
    .container{
        padding: 0px 8px;
    }
    .text-center-h2{
        margin-top: 0;
    }
    .contact-btn{
        font-size: 16px;
    }
    .contact-btn:hover:after{
        top: -2px;
    }
}